<template>
    <div>
        <HeaderCard :loading="loading" tittle="Reagendamentos" @onLoad="load" />
        <Chart type="bar" :data="stackedData" :options="stackedOptions" class="h-30rem" :plugins="plugins" />
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';
import moment from 'moment-timezone';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    components: {
        HeaderCard
    },
    props: {
        customerIds: {
            type: Array
        },
        grupoIds: {
            type: Array
        },
        customerBranchIds: {
            type: Array
        },
        usersIds: {
            type: Array
        }
    },
    data() {
        return {
            dados: [],
            periodicidade: 'month',
            quantidade: 12,
            loading: false,
            plugins: [
                ChartDataLabels,
                {
                    beforeInit(chart) {
                        const originalFit = chart.legend.fit;

                        chart.legend.fit = function fit() {
                            originalFit.bind(chart.legend)();
                            this.height += 25;
                        };
                    }
                }
            ],
            dataInicio: null,
            dataFim: null
        };
    },
    computed: {
        stackedData() {
            return {
                labels: this.dados
                    .map((item) => this.formatarData(item.quebra))
                    .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []),
                datasets: [
                    {
                        label: 'Reagendamentos',
                        data: this.dados.map((p) => p.reagendamentos),
                        porcentagem: this.dados.map((p) => p.porcentagem_reagendamentos),
                        backgroundColor: '#FF6384',
                        borderColor: '#FF6384',
                        borderWidth: 1
                    },
                    {
                        label: 'Solicitações',
                        data: this.dados.map((p) => p.solicitacoes),
                        backgroundColor: '#36A2EB',
                        borderColor: '#36A2EB',
                        borderWidth: 1
                    }
                ]
            };
        },
        stackedOptions() {
            return {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    legend: {
                        labels: {
                            boxHeight: 10,
                            fontColor: '#495057',
                            font: {
                                weight: 500
                            }
                        }
                    },
                    datalabels: {
                        formatter: (_value, context) => {
                            const porcentagem = context.dataset.porcentagem?.[context.dataIndex];
                            const quantidade = context.dataset.data[context.dataIndex];
                            if (porcentagem && quantidade) return [`${porcentagem}%`, quantidade];
                        },
                        anchor: 'end',
                        align: 'top',
                        labels: {
                            value: {
                                color: 'black',
                                font: {
                                    size: 12
                                }
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057',
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            display: false,
                            drawBorder: false,
                            color: '#EBEDEF'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#EBEDEF',
                            drawBorder: false
                        }
                    }
                }
            };
        }
    },

    methods: {
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/reagendamentos/por-clientes', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim,
                            customerIds: this.customerIds?.length ? this.customerIds : undefined,
                            grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                            customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined,
                            userIds: this.usersIds?.length ? this.usersIds : undefined
                        })
                    }
                });
                this.dados = data;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        },
        formatarData(data) {
            let formato;
            if (this.periodicidade === 'day') formato = 'DD/MM/YY';
            if (this.periodicidade === 'month') formato = 'MMM/YY';
            if (this.periodicidade === 'year') formato = 'YYYY';

            return moment.tz(data, 'UTC').format(formato);
        }
    }
};
</script>
